import "./src/assets/style/global.css";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React, { useState } from "react";
import { CurrentPodcastProvider } from "./src/hooks/currentPodcastContext";
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeD2DEiAAAAADh45ntTUBkQdTBxEE28QWbagfK_">
      <CurrentPodcastProvider>{element}</CurrentPodcastProvider>
    </GoogleReCaptchaProvider>
  );
};
