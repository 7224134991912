import { keyframes } from "@emotion/react";

const slideInLeftCenter = keyframes`
  0% {
    transform: translateX(0%);
  }
  33% {
    transform: translateX(100%);
    visibility:hidden;
  }
  66% {
    transform: translateX(-100%);
    visibility:hidden;
  }
  100% {
    transform: translateX(0%);
    visibility:visibile;
  }
`;

const theme = {
  colors: {
    text: "#171717",
    secondaryText: "#171717",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#01D7E2",
    secondary: "#1E6BB1",
    light: "#ffffff",
    dark: "#00000A",
    grey: "#C6C6C6",
    error: "linear-gradient(to right, #FF004E, #970165)",
    errorflat:"#FF004E"
  },
  breakpoints: ["480px", "768px", "1025px", "1440px"],
  fonts: {
    body: 'neue-haas-grotesk-display, system-ui, -apple-system, Arial, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'neue-haas-grotesk-display, system-ui, -apple-system, Arial, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [
    0, 4, 8, 15, 30, 40, 48, 64, 80, 96, 128, 192, 210, 256, 320, 384, 448, 512,
  ],
  fontSizes: [
    12, 14, 15, 18, 21, 24, 30, 34, 40, 48, 64, 80, 96, 150, 168, 196,
  ],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.33,
    heading: 1.125,
  },
  sizes: {
    container: 1370,
  },
  radius: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: ["42px", "42px", 8, 9],
      fontFamily: "heading",
      lineHeight: "1.067",
      fontWeight: "400",
      textTransform: "lowercase",
      color: "light",
      mb: 3,
    },
    h2: {
      fontSize: [5, 6],
      fontFamily: "body",
      lineHeight: [1.2, "48px"],
      fontWeight: 300,
      color: "light",
      mb: 3,
      mt: 3,
    },
    h3: {
      fontSize: [4, 5],
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: 300,
      color: "light",
      mb: 3,
      mt: 3,
    },
    h4: {
      fontSize: [4],
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: 300,
      color: "light",
      mb: 2,
      mt: 3,
    },
    h5: {
      fontSize: 3,
      color: "light",
      fontFamily: "body",
      lineHeight: "heading",
      fontWeight: "body",
      mb: 2,
      mt: 3,
    },
    h6: {
      fontSize: 2,
      color: "dark",
      fontFamily: "heading",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 3,
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.4,
    },
    p: {
      fontWeight: 400,
      fontSize: [2],
      fontWeight: "300",
      letterSpacing: ".25px",
      lineHeight: "body",
    },
    kicker: {
      fontSize: [0, 1],
      fontFamily: "heading",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "primary",
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    lg: {
      padding: [2, 3],
    },
    customFW: {
      maxWidth: "100%",
      px: [3, 4, 6, 8],
      py: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  svg: {
    arrow: {
      primary: {
        svg: {
          circle: {
            stroke: "primary",
            strokeWidth: "2px",
          },
          use: {
            fill: "primary",
          },
          "g#Group": {
            fill: "primary",
          },
        },
      },
      dark: {
        svg: {
          circle: {
            stroke: "dark",
            strokeWidth: "2px",
          },
          use: {
            fill: "dark",
          },
          "g#Group": {
            fill: "dark",
          },
        },
      },
      light: {
        svg: {
          circle: {
            stroke: "light",
            strokeWidth: "2px",
          },
          use: {
            fill: "light",
          },
          "g#Group": {
            fill: "light",
          },
        },
      },
    },
  },
  buttons: {
    primaryTransparent: {
      px: [3],
      py: [2],
      textDecoration: "none",
      cursor: "pointer",
      color: "light",
      "*": {
        fontSize: [1],
      },
    },
    primary: {
      px: [3],
      py: [2],
      fontSize: [1],
      textDecoration: "none",
      cursor: "pointer",
      color: "light",
      background: "dark",

      "&:hover": {
        background: `linear-gradient(to right, #01D7E2, #1E6BB1)`,
        span: {
          color: "light",
        },
      },
    },
    primaryEmpty: {
      px: [3],
      py: [2],
      fontSize: [1],
      textDecoration: "none",
      cursor: "pointer",
      color: "primary",
      // "&:hover": {
      //   bg: "dark",
      // },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
    primaryForm: {
      paddingY: 3,
      paddingX: 5,
      cursor: "pointer",
      color: "primary",
      bg: "light",
      borderRadius: "0px",
      border: "1px solid",
      borderColor: "transparent",
      "&:hover": {
        border: "1px solid",
        borderColor: "light",
        color: "light",
        bg: "primary",
        svg: {
          stroke: "light",
        },
      },
    },
    primaryFormEmpty: {
      paddingY: 2,
      paddingX: 3,
      cursor: "pointer",
      color: "light",
      borderRadius: "0px",
      border: "1px solid",
      borderColor: "dark",
      backgroundColor: "dark",
      "&:hover": {
        border: "1px solid",
        borderColor: "light",
        color: "light",
        bg: "primary",
        svg: {
          stroke: "light",
        },
      },
    },
  },
  inputs: {
    primary: {
      borderColor: "light",
      p: [3],
      border: "unset",
      borderRadius: "0px",
      cursor: "pointer",
      color: "light",
      backgroundColor: "dark",
      fontSize: [3, 5],
      "&:focus": {
        outline: "none",
        backgroundColor: "dark",
        color: "light",
        border: "unset",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "light",
        opacity: 1 /* Firefox */,
      },
      "::placeholder": { color: "primary", opacity: 1 },
      ":-ms-input-placeholder": { color: "primary" },
      "::-ms-input-placeholder": { color: "primary" },
    },
    secondary: {
      borderColor: "secondary",
      p: [3],
      border: "1px solid",
      borderRadius: "0px!important",
      cursor: "pointer",
      color: "secondary",
      "&:focus": {
        outline: "none",
        color: "primary",
        border: "1px solid",
        borderColor: "primary",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "secondary",
        opacity: 1 /* Firefox */,
      },
    },
    dark: {
      borderColor: "dark",
      p: [3],
      border: "1px solid",
      borderRadius: "0px!important",
      cursor: "pointer",
      color: "dark",
      "&:focus": {
        outline: "none",
        backgroundColor: "dark",
        color: "white",
        border: "1px solid dark",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "dark",
        opacity: 1 /* Firefox */,
      },
    },
  },
  links: {
    simplePrimary: {
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      fontFamily: "heading",
      color: "primary",
      fontSize: [0],
      textTransform: "uppercase",
      "div svg": {
        overflow: "hidden",
      },
      "svg *": {
        stroke: "primary",
      },
      "&:hover": {
        svg: {
          animation: `${slideInLeftCenter} 0.4s linear .25s forwards`,
          width: "34px",
        },
      },
    },
    primary: {
      borderRadius: "0",
      backgroundColor: "transparent",
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      fontFamily: "heading",
      color: "primary",
      p: [3],
      px: [4],
      border: "1px solid",
      borderColor: "primary",
      textTransform: "uppercase",
      fontSize: [0],
      "svg *": {
        stroke: "primary",
      },
      "&:hover": {
        svg: {
          animation: `${slideInLeftCenter} 0.4s linear .25s forwards`,
          width: "34px",
        },
      },
    },
    secondary: {
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      fontFamily: "heading",
      color: "secondary",
      p: [3],
      px: [4],
      border: "1px solid",
      borderColor: "secondary",
      textTransform: "uppercase",
      fontSize: [0],
      "svg *": {
        stroke: "secondary",
      },
      "&:hover": {
        svg: {
          animation: `${slideInLeftCenter} 0.4s linear .25s forwards`,
          width: "34px",
        },
      },
    },
    light: {
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      fontFamily: "heading",
      color: "light",
      p: [3],
      px: [4],
      border: "1px solid",
      borderColor: "light",
      textTransform: "uppercase",
      fontSize: [0],
      "div svg": {
        overflow: "hidden",
      },
      "svg *": {
        stroke: "light",
      },
      "&:hover": {
        svg: {
          animation: `${slideInLeftCenter} 0.4s linear .25s forwards`,
          width: "34px",
        },
      },
    },
    nav: {
      light: {
        li: {
          fontFamily: "heading",
          color: "secondary",
          a: {
            color: "inherit",
            "&.active": {
              color: "inherit",
            },
          },
        },
      },
      secondary: {
        li: {
          fontFamily: "heading",
          color: "light",
          a: {
            color: "inherit",
            "&.active": {
              color: "inherit",
            },
          },
        },
      },
      ".dropdown-menu": {
        ul: {
          li: {
            display: "flex",
            alignItems: "cemter",
            justifyContent: "center",
            p: [0],
            fontFamily: "heading",
            textAlign: "center",
            color: "light",
            my: [1],
            maxWidth: "240px",
            a: {
              width: "100%",
              display: "inline-block",
              py: [3],
              px: [3],
              textAlign: "center",
              color: "inherit",
              fontSize: [0],
              backgroundColor: "secondary",
              "&.active": {
                backgroundColor: "secondaryLight",
                color: "inherit",
              },
            },
          },
        },
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      overscrollBehaviorX: "contain",
      backgroundColor: "dark",
      "*": {
        color: "light",
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        "text-shadow": "1px 1px 1px rgba(0,0,0,0.004)",
      },
      fontFamily: "body",
      fontWeight: "body",
      input: {
        transition:
          "background-color .2s linear, border-color .2s linear,  color .2s linear",
      },
      "a,button": {
        fontFamily: "heading",
        "-webkit-transition":
          "background-color .2s linear, background .2s linear",
        "-ms-transition": "background-color .2s linear, background .2s linear",
        transition: "background-color .2s linear, background .2s linear",
        textDecoration: "none",
        textDecoration: "underline",
        "&.active": {
          fontWeight: "800",
          color: "primary",
          //textDecoration: "underline",
        },
        // color: "primary",
        // "&:hover": {
        //   textDecoration: "none",
        // },
      },
      "--swiper-theme-color": "#EA0029",
      ".swiper-container": { pb: 5 },
      ".oneSide": {
        p: [3, 4],
        pr: [0, 0, 0, 0],
        mr: [0, 0, 0, 0],
        ml: [" calc(50vw - 375px)", "calc(50vw - 485px)", "calc(50vw - 640px)"],
      },
      "input::placeholder,input:-ms-input-placeholder,input::-ms-input-placeholder":
        {
          fontWeight: "500!important",
        },
    },
  },
};

export default theme;
