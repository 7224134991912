import { navigate } from "gatsby";
import React, { createContext, useEffect, useState } from "react";

const CurrentPodcastContext = createContext({});

export const CurrentPodcastProvider = ({ children }) => {
  const [currentPodcast, setCurrentPodcast] = useState({});
  const value = { currentPodcast, setCurrentPodcast };

  return (
    <CurrentPodcastContext.Provider value={value}>
      {children}
    </CurrentPodcastContext.Provider>
  );
};

export default CurrentPodcastContext;
